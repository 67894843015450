import Grid from 'atoms/Grid';
import createComponents from 'libs/notion-renderer/createComponents';
import { NotionProps } from 'notion/types';

const NotionGrid: React.FC<NotionProps> = ({
  block: { loadedContent, gridColumns },
}) => {
  const columns = createComponents(loadedContent);

  return <Grid numberOfColumns={gridColumns}>{columns}</Grid>;
};

export default NotionGrid;
